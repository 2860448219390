<div class="card shadow py-3 card-subsystem">
  <div class="card-body">
    <div class="row">
      <div class="col-auto">
        <div class="col d-flex" [style]="'height: 120px; width: 120px; background-color:' + system.color ">
          <img class="w-50 mx-auto" [src]="system.image" [alt]="system.name">
        </div>
      </div>
      <div class="col align-self-center">
        <div class="row">
          <div class="col">
            <div class="h2 font-weight-normal">{{ system.name }}</div>
            <div>{{ system.description || '&nbsp;' }}</div>
          </div>
          <div class="col-auto align-self-center" [style.min-width.px]="225">
            <app-download-button [subsystem]="subsystem"></app-download-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
