<div class="row bg-primary shadow text-white rounded position-relative">
  <div class="col-auto">
    <div class="bg-white rounded-circle medal-circle">
      <div class="border border-success rounded-circle border-shape"></div>
    </div>
  </div>
  <div class="col-auto"></div>
  <div class="col h3 px-5 py-4">
    <span class="font-weight-normal">Please Wait</span>
  </div>
</div>
