import { Component, Input } from '@angular/core';
import { Subsystem } from '../../models/subsystem';

@Component({
  selector: 'app-subsystem',
  templateUrl: './subsystem.component.html',
  styleUrls: ['./subsystem.component.scss']
})
export class SubsystemComponent {
  @Input() public subsystem: Subsystem;

  get system() {
    return { ...this.subsystem, color: '#53AD53', image: '/assets/kiva_logo.svg' };
  }
}
