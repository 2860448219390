import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'mer-tabs',
  templateUrl: './tabs.component.html'
})
export class TabsComponent implements OnInit {
  @Input() tabs: string[];
  @Input() direction: 'horizontal' | 'vertical';
  @Input() selectedIndex: number;
  @Output() selectedIndexChanged = new EventEmitter<number>();

  public ngOnInit() {
    this.selectedIndex = this.selectedIndex || 0;
  }

  public select(index: number) {
    this.selectedIndex = index;
    this.selectedIndexChanged.emit(index);
  }
}
