import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from './angular-material.module';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { UIFrameworkModule } from '../ui-framework/ui-framework.module';
import { WizardComponent } from './components/wizard-component/wizard.component';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    MessageDialogComponent,
    WizardComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    UIFrameworkModule
  ],
  exports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    ConfirmDialogComponent,
    MessageDialogComponent,
    UIFrameworkModule
  ],
  providers: [
  ],
  entryComponents: [
    ConfirmDialogComponent,
    MessageDialogComponent
  ],
})
export class SharedModule { }
