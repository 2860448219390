import { Component, OnInit } from '@angular/core';
import { SubsystemService } from '../../services/subsystem.service';
import { Subsystem } from '../../models/subsystem';
import { DialogService } from 'src/app/modules/shared/services/dialog.service';

@Component({
  selector: 'app-publications',
  templateUrl: './publications.component.html',
  styleUrls: ['./publications.component.scss']
})
export class PublicationsComponent implements OnInit {
  public loading: boolean;
  public subsystems: Subsystem[];

  public constructor(
    private dialogService: DialogService,
    private subsystemService: SubsystemService) {
  }

  public async ngOnInit() {
    this.loading = true;
    try {
      this.subsystems = await this.subsystemService.getAll();
    } catch (e) {
      await this.dialogService.message('Error', `Error loading publications. Please try again and contant the administrator if the error persists.`);
      throw e;
    } finally {
      this.loading = false;
    }
  }
}
