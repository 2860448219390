import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RemoteService } from '../../core/services/remote.service';
import { Subsystem } from '../models/subsystem';
import { Version } from '../models/version';

@Injectable({
  providedIn: 'root'
})
export class SubsystemService {
  private baseUrl: string = environment.serverBaseUrl;

  constructor(private remoteService: RemoteService) {
  }

  public getAll(): Promise<Subsystem[]> {
    const url = `/subsystems/${environment.ark.organization}/${environment.ark.projectId}/${environment.ark.environment}`;
    return this.remoteService.get<Subsystem[]>(this.baseUrl + url);
  }

  public getVersions(subsystem: Subsystem): Promise<Version[]> {
    const url = `/subsystems/${environment.ark.organization}/${environment.ark.projectId}/${environment.ark.environment}/${subsystem.id}/versions`;
    return this.remoteService.get<Version[]>(this.baseUrl + url);
  }

  public async getDownloadUrl(subsystem: Subsystem, version: Version): Promise<string> {
    const url = `/subsystems/${environment.ark.organization}/${environment.ark.projectId}/${environment.ark.environment}/${subsystem.id}/${version.id}/download-url`;
    const response = await this.remoteService.get<any>(this.baseUrl + url);
    return response.url;
  }
}
