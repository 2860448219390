import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  OnDestroy,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Location } from '@angular/common';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit, OnDestroy {
  public loadedStep: WizardStep;
  public config: WizardConfig;
  public stepIndex = 0;
  private _loadedComponent: ComponentRef<WizardStepComponent<any>>;
  private _data: any;
  private _params: Params;

  @ViewChild('container', { read: ViewContainerRef, static: false })
  public container: ViewContainerRef;

  get previousButtonText(): string {
    return this._loadedComponent && this._loadedComponent.instance.previousButtonText
      ? this._loadedComponent.instance.previousButtonText
      : 'Back';
  }

  get continueButtonText(): string {
    return this._loadedComponent && this._loadedComponent.instance.continueButtonText
      ? this._loadedComponent.instance.continueButtonText
      : 'Next';
  }

  get stepTitle(): string {
    return this._loadedComponent
      ? this._loadedComponent.instance.title
      : '';
  }

  public constructor(
    private _router: Router,
    private _location: Location,
    private _route: ActivatedRoute,
    private _componentFactoryResolver: ComponentFactoryResolver) {
  }

  public async ngOnInit(): Promise<void> {
    this._params = await this._route.params.pipe(first()).toPromise();
    this.config = ((await this._route.data.pipe(first()).toPromise()) as WizardConfig);
    this._data = this.config.initialData || {};

    if (!this.config) {
      this._router.navigate(['/']);
    }
    this._loadStep();
  }

  public async ngOnDestroy() {
    if (this._loadedComponent) {
      this._loadedComponent.destroy();
    }
  }

  public isStepValid() {
    if (!this._loadedComponent) {
      return false;
    }

    return this._loadedComponent.instance.isValid();
  }

  public async continue() {
    if (!this._loadedComponent || await this._loadedComponent.instance.continue() === false) {
      return;
    }

    if (this.stepIndex < this.config.steps.length - 1) {
      this.stepIndex++;
      this._loadStep();
    }
  }

  public back() {
    if (this.stepIndex > 0) {
      this.stepIndex--;
      this._loadStep();
    } else {
      this._location.back();
    }
  }

  private _loadStep() {
    if (this._loadedComponent) {
      this._loadedComponent.destroy();
      this._loadedComponent = undefined;
    }
    if (this.config.steps.length > this.stepIndex) {
      this.loadedStep = this.config.steps[this.stepIndex];
      this.container.clear();
      const factory = this._componentFactoryResolver.resolveComponentFactory(this.loadedStep.component);
      this._loadedComponent = this.container.createComponent(factory);
      this._loadedComponent.instance.wizardData = this._data;
      this._loadedComponent.instance.params = this._params;

      if (this.loadedStep.config) {
        this._loadedComponent.instance.config = this.loadedStep.config;
      }
    }
  }
}

export interface WizardConfig {
  title: string;
  initialData?: any;
  steps: WizardStep[];
}

export interface WizardStep {
  component: Type<any>;
  config?: any;
}

export interface WizardStepComponent<T> {
  wizardData: T;
  config?: any;
  title: string;
  params?: {
    [key: string]: any;
  };
  previousButtonText?: string;
  continueButtonText?: string;
  isValid: () => boolean;
  continue: () => Promise<boolean>;
}
