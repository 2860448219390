<div class="row h-100 align-items-center justify-content-center" style="display: flex;align-items: center">
  <div class="col-5">
    <div *ngIf="error" class="alert alert-danger alert-dismissible">{{ error }}</div>
    <div class="card">
      <div class="card-header">Login</div>
      <div class="card-body">
        <form #form="ngForm" (ngSubmit)="onSubmit(form)" novalidate>
          <div class="form-group">
            <label for="username">Username or email: {{ username }}</label>
            <input type="email" class="form-control" [ngModel]="username" name="username" id="username" required ngModel>
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input type="password" class="form-control" [ngModel]="password" name="password" id="password" required ngModel>
          </div>
          <button type="submit" class="btn btn-primary btn-block" [disabled]="!form.valid">Submit</button>
        </form>
      </div>
    </div>
  </div>
</div>
