// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environmentName: 'dev',
  serverBaseUrl: 'https://us-central1-kiva-public-distribution-dev.cloudfunctions.net/api',
  auth0: {
    domain: 'product-central-dev.auth0.com',
    clientID: '0iMhCsf0B42wtLGiDqpCyeiywNytXC8H',
    realm: 'Kiva'
  },
  ark: {
    organization: 'Kiva',
    projectId: 'fkRLN7YtxDirFZOzW3Ug',
    environment: 'development'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
