import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
  ],
  exports: [
    MatIconModule,
    MatDialogModule
  ],
  providers: [],
})
export class AngularMaterialModule { }
