import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { CoreModule } from '../core.module';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: CoreModule
})
export class RemoteService {

  constructor(private httpClient: HttpClient, private auth: AuthService) {
  }

  private defaultTimeout = 1200000;

  private async getHttpOptions(): Promise<any> {

    const headers: any = {
      'content-type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${this.auth.token}`
    };

    return {
      headers: new HttpHeaders(headers)
    };
  }

  public get<T>(url: string): Promise<T> {
    return new Promise(async (resolve, reject) => {
      this.httpClient.get(url, await this.getHttpOptions())
        .pipe(timeout(this.defaultTimeout))
        .subscribe((data: any) => resolve(data as T), error => reject(error));
    });
  }

  public post<TInput, TOutput>(url: string, body: TInput): Promise<TOutput> {
    return new Promise(async (resolve, reject) => {
      const options = await this.getHttpOptions();
      this.httpClient.post(url, body, options)
        .pipe(timeout(this.defaultTimeout))
        .subscribe((data: any) => resolve(data as TOutput), error => reject(error));
    });
  }

  public put<TInput, TOutput>(url: string, body: TInput): Promise<TOutput> {
    return new Promise(async (resolve, reject) => {
      const options = await this.getHttpOptions();
      this.httpClient.put(url, body, options)
        .pipe(timeout(this.defaultTimeout))
        .subscribe((data: any) => resolve(data as TOutput), error => reject(error));
    });
  }

  public delete(url: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const options = await this.getHttpOptions();
      this.httpClient.delete(url, options)
        .pipe(timeout(this.defaultTimeout))
        .subscribe((data: any) => resolve(data), error => reject(error));
    });
  }
}
