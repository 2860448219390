<div id="root">
  <div id="header" *ngIf="config && loadedStep">
    <span class="steps">Step {{stepIndex + 1}} of {{config.steps.length}}</span>
    <span class="title">{{config.title}}</span>
    <span class="step-text">{{stepTitle}}</span>
  </div>

  <template #container>
  </template>

  <div id="actions" *ngIf="config">
    <button (click)="back()" >{{previousButtonText}}</button>
    <span class="steps">Step {{stepIndex + 1}} of {{config.steps.length}}</span>
    <button class="primary" [disabled]="!isStepValid()" (click)="continue()">{{continueButtonText}}</button>
  </div>
</div>
