
<div class="root">
  <div *ngIf="!editMode" class="content">
    <input type="text" [disabled]="true" [(ngModel)]="value">
    <button class="rounded" aria-label="Edit" (click)="setEditMode()">
      <mat-icon>edit</mat-icon>
    </button>
  </div>
  <div *ngIf="editMode" class="content">
      <input (keyUp.enter)="changeValue()" [disabled]="loading" type="text" [(ngModel)]="edition">
      <button class="rounded" [disabled]="loading" *ngIf="edition && !error"  aria-label="Save" (click)="changeValue()">
        <mat-icon *ngIf="!loading">done</mat-icon>
        <mat-icon *ngIf="loading">hourglass_empty</mat-icon>
      </button>
      <button class="rounded" *ngIf="!loading" aria-label="Cancel" (click)="cancelEditMode()">
        <mat-icon>close</mat-icon>
      </button>
  </div>
  <div class="input-error" *ngIf="error">{{error}}</div>
</div>