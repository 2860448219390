import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Auth0TokenKey, Auth0UserKey, AuthService } from '../../../core/services/auth.service';
import { parse } from 'query-string';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {

  constructor(public auth: AuthService, protected activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    let params: any = {};
    this.activatedRoute.fragment.subscribe(fragment => (params = (parse(fragment))));
    if (!params.access_token) {
      return !!this.auth.profile.email ? this.auth.redirectToHome() : this.auth.redirectToLogin();
    }

    return this.auth.webAuth.client.userInfo(params.access_token, (err, user) => {

      if (err) {
        localStorage.removeItem(Auth0UserKey);
        return this.auth.redirectToLogin();
      }

      localStorage.setItem(Auth0UserKey, JSON.stringify(user));
      localStorage.setItem(Auth0TokenKey, params.access_token);

      return this.auth.redirectToHome();
    });
  }
}
