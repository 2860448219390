import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { first } from 'rxjs/operators';
import { MessageDialogComponent } from '../components/message-dialog/message-dialog.component';
import { SharedModule } from '../shared.module';

@Injectable({
  providedIn: SharedModule
})
export class DialogService {
  constructor(private dialog: MatDialog) {
  }

  public confirm(title: string, message: string): Promise<boolean> {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title,
        message
      }
    });
    return dialog.afterClosed().pipe(first()).toPromise();
  }

  public message(title: string, message: string): Promise<void> {
    const dialog = this.dialog.open(MessageDialogComponent, {
      data: {
        title,
        message
      }
    });
    return dialog.afterClosed().pipe(first()).toPromise();
  }
}
