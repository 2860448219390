import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { EditableFieldComponent } from './components/editable-field/editable-field.component';
import { AngularMaterialModule } from '../shared/angular-material.module';

@NgModule({
  declarations: [
    HeaderComponent,
    TabsComponent,
    EditableFieldComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule
  ],
  exports: [
    HeaderComponent,
    TabsComponent,
    EditableFieldComponent
  ],
  entryComponents: [
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UIFrameworkModule { }
