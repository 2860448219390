import { Component, Input } from '@angular/core';

@Component({
  selector: 'mer-editable-field',
  templateUrl: './editable-field.component.html',
  styleUrls: ['./editable-field.component.scss']
})
export class EditableFieldComponent {
  public editMode = false;
  @Input() public value: string;
  @Input() public objectContext: object;
  @Input() public valueChanged: Function;
  @Input() public validate: Function;
  public loading: boolean;

  private _edition: string;
  public get edition(): string {
    return this._edition;
  }

  public set edition(value: string) {
    this._edition = value;
    this.hasErrors();
  }

  public error: string;

  public setEditMode() {
    this.edition = this.value;
    this.editMode = true;
  }

  public cancelEditMode() {
    this.editMode = false;
  }

  public async changeValue() {
    this._validateRequiredAttributes();
    try {
      this.loading = true;
      if (this.edition) {
        const callback = this.valueChanged.bind(this.objectContext);
        await callback(this.edition);
      }
      this.value = this.edition;
    } finally {
      this.loading = false;
      this.editMode = false;
    }
  }

  public hasErrors() {
    if (this.validate) {
      const callback = this.validate.bind(this.objectContext);
      this.error = callback(this.edition);
      return !!this.error;
    }
    return false;
  }

  private _validateRequiredAttributes() {
    if (!this.valueChanged) {
      throw new Error('Missing [valueChanged] attribute on editable-field component.');
    }

    if (!this.objectContext) {
      throw new Error('Missing [objectContext] attribute on editable-field component.');
    }
  }
}
