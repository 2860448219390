import { Component, Input } from '@angular/core';

@Component({
  selector: 'mer-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent {
  @Input() public title: string;
  @Input() public showBottomLine: boolean;
}
