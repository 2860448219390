import { Injectable } from '@angular/core';
import { Auth0Callback, WebAuth } from 'auth0-js';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

export const Auth0UserKey = 'auth0_user';

export const Auth0TokenKey = 'auth0_token';

@Injectable({ providedIn: 'root' })
export class AuthService {
  webAuth = new WebAuth({
    domain: environment.auth0.domain,
    clientID: environment.auth0.clientID,
    redirectUri: `${window.location.origin}/callback`,
    responseType: 'token id_token'
  });

  constructor(protected router: Router) {
  }

  login(username, password, callback: Auth0Callback<any> = console.log) {

    return this.webAuth.login({ username, password, realm: environment.auth0.realm }, callback);
  }

  get profile(): false | any {
    let auth = localStorage.getItem(Auth0UserKey);
    if (auth) {
      auth = JSON.parse(auth);

      if (typeof auth === 'object') {
        return auth;
      }
    }

    return false;
  }

  get token() {
    return localStorage.getItem(Auth0TokenKey);
  }

  get isLoggedIn(): boolean {
    return this.profile !== false;
  }

  logout(redirectToLogin = true) {
    localStorage.removeItem(Auth0UserKey);
    localStorage.removeItem(Auth0TokenKey);

    if (redirectToLogin) {
      return this.redirectToLogin();
    }
  }

  public redirectToLogin() {
    return this.router.navigateByUrl('/login');
  }

  public redirectToHome() {
    return this.router.navigateByUrl('/');
  }
}
