import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subsystem } from '../../models/subsystem';
import { Version } from '../../models/version';
import { SubsystemService } from '../../services/subsystem.service';
import { DialogService } from 'src/app/modules/shared/services/dialog.service';

@Component({
  selector: 'app-download-button',
  templateUrl: './download-button.component.html',
  styleUrls: ['./download-button.component.scss']
})
export class DownloadButtonComponent implements OnInit {
  @Input() subsystem: Subsystem;
  @Output() selectedVersionChanged = new EventEmitter<Version>();
  loading = true;
  selectedVersionIndex = 0;
  downloadLink: string;

  constructor(private dialogService: DialogService, private subsystemService: SubsystemService) {

  }

  get selectedVersion(): Version | undefined {
    return this.subsystem && this.subsystem.versions && this.subsystem.versions.length > this.selectedVersionIndex
      ? this.subsystem.versions[this.selectedVersionIndex]
      : undefined;
  }

  get selectedVersionName() {
    return this.selectedVersion?.name || '';
  }

  async ngOnInit() {
    this.loading = true;

    await this.subsystemService.getDownloadUrl(this.subsystem, this.selectedVersion)
      .then(link => (this.downloadLink = link))
      .catch(console.log);

    this.loading = false;
  }

  async download() {
    if (!this.downloadLink) {
      return this.dialogService.message('Error', 'There was an error resolving download URL. Please try again and contant the administrator if the error persists.');
    }

    return window.open(this.downloadLink);
  }

}
