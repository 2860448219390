import { Component } from '@angular/core';
import { AuthService } from '../../../../core/services/auth.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  username: string;
  password: string;
  error: string;

  constructor(public auth: AuthService) {
  }

  onSubmit({ form }: NgForm) {
    this.error = undefined;

    if (!form.valid) {
      return;
    }

    return this.auth.login(form.value.username, form.value.password, (response) => {
      if (response) {
        this.error = response.description;
      }
    });
  }
}
