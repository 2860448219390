<div class="row h-100 align-items-center justify-content-center" style="display: flex;align-items: center">
  <div id="content" class="w-100 row flex-column" *ngIf="!loading && subsystems && subsystems.length > 0">
    <app-subsystem *ngFor="let subsystem of subsystems" [subsystem]="subsystem" class="mb-4"></app-subsystem>
  </div>

  <app-loading *ngIf="loading"></app-loading>

  <div class="card inactive" *ngIf="!loading && (!subsystems || subsystems.length === 0)">
    <div class="card-body placeholder">
      <div class="illustration empty"></div>
      <span class="title">No publications yet</span>
      <span class="subtitle">There are no available publications.</span>
    </div>
  </div>
</div>
